"use strict";

const m = require("mithril");

const css = require("./anet-dimebar.css");

const ga = require("../ga");

const strings     = require("./anet-dimebar.csv");
const siteNavData = require("../../../Code/Data/navbarsdata.json");

module.exports = {
    oninit(vnode) {
        const { lang, langs } = vnode.attrs;

        const i18n = strings[lang];

        vnode.state.otherLangs = langs.filter(_lang => _lang !== lang);

        vnode.state.dimebarLeft     = i18n.nav.navlinks;
        vnode.state.dimebarLeftKeys = Object.keys(vnode.state.dimebarLeft);

        vnode.state.dimebarRight     = i18n.nav.dimebar;
        vnode.state.dimebarRightKeys = Object.keys(vnode.state.dimebarRight);
    },

    oncreate() {
        ga.clickTracker();
    },

    view(vnode) {
        const { pageName, excludeDimebar, position, opacity, lang }                        = vnode.attrs;
        const { dimebarLeft, dimebarLeftKeys, dimebarRight, dimebarRightKeys, otherLangs } = vnode.state;

        const i18n = strings[lang];

        let { pathname } = window.location;

        // nav bar container
        return m("nav", {
                class        : position === "absolute" ? css.anetDimebarAbs : css.anetDimebar,
                "data-title" : i18n.logoTitle,
                "aria-label" : "ArenaNet Menu",
                "data-test"  : "dimebar",
                style        : `opacity:${opacity || 1}`
            },

            m("div", { class : css.wrapper },

                m("div", { class : css.primaryNav },

                    // anet logo - static
                    m("a", {
                        class              : css.anetLogo,
                        href               : `https://www.arena.net/${lang}`,
                        "data-ga-event"    : "",
                        "data-ga-category" : "Header"
                    }, "ArenaNet"),

                    // list of nav items based on csv values
                    dimebarLeftKeys.map(idx =>
                        m("a", {
                                href               : `https://www.arena.net/${lang}/${dimebarLeft[idx].slug}`,
                                "data-ga-event"    : "",
                                "data-ga-category" : "Header",
                                "data-selected"    : pageName === dimebarLeft[idx].slug ? "current" : "",
                            },
                            dimebarLeft[idx].name
                        )
                    ),

                    // link for merch store - static
                    m("a", {
                            href               : `${i18n.nav.shoplink.slug}`,
                            target             : "_blank",
                            rel                : "noreferrer",
                            "data-ga-event"    : "",
                            "data-ga-category" : "Header"
                        },
                        i18n.nav.shoplink.name
                    )
                ),

                // right-justified links
                m("ul", { class : css.supportInfo },

                    excludeDimebar ?
                        null :
                        dimebarRightKeys
                            .filter(key => {
                                // if vnode.attrs[key] is false, don't show that link.
                                // e.g. { login : false }
                                const doNotUse = vnode.attrs[key] === false;
                                // necessary bc dimebarRightKeys is created using the csv, which has more keys than our default themes do.
                                const notInDefaultNavNorCustom =
                                    !siteNavData.dimebar[key] &&
                                    !vnode.attrs[key];

                                return !(doNotUse || notInDefaultNavNorCustom);
                            })
                            .map(key => {
                                // diff dimebars might not want all links, or might have custom urls.
                                // vnode.attrs[key] will be an  obj with custom attrs, like an in-app route link.
                                // if undefined, use the siteNavData json info instead.
                                const keyAttrs = Object.assign({
                                        "data-ga-event"    : "",
                                        "data-ga-category" : "dimebar",
                                        "data-test"        : key
                                    },
                                    vnode.attrs[key] || {
                                        href   : siteNavData.dimebar[key]?.[lang] || vnode.attrs[key],
                                        target : "_blank",
                                        rel    : "noopener",
                                    }
                                );

                                let copy = dimebarRight[key];

                                // show "Account" instead of "Login" on any site that isn't account site
                                if (key === "login" && !window.location.href.includes("account") && !vnode.attrs.login) {
                                    copy = dimebarRight.account;
                                }

                                return m("li",
                                    m("a",
                                        keyAttrs,
                                        copy
                                    )
                                );
                            }),

                    // drop-down menu for language selection
                    otherLangs.length ?
                        m("li", { class : css.langSelectBar },
                            m("a", {
                                class : css.currentLang,
                                onmouseover() {
                                    // this really just triggers a redraw, and updates `pathname` at the top of `view()`
                                    pathname = window.location.pathname;
                                }
                            }, lang),

                            otherLangs.map(_lang => m("a", { href : pathname.replace(lang, _lang) }, _lang))
                        ) :
                        null
                )
            )
        );
    }
};
